import { Component, OnInit ,Inject, EventEmitter } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { OrdersService } from 'src/app/services/order-service';
import {MatDialog } from '@angular/material/dialog';
import { Router} from '@angular/router';
import { BussinssService } from 'src/app/services/bussinss-service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-popup-isCorectOrder',
  templateUrl: './popup-isCorectOrder.component.html',
  styleUrls: ['./popup-isCorectOrder.component.scss']
})
export class PopupIsCorectOrderComponent implements OnInit {

  phoneNumber : string = ""
  constructor(  @Inject(MAT_DIALOG_DATA) private data:  {myNumber: string },public dialogRef: MatDialogRef<PopupIsCorectOrderComponent> ) { }

  ngOnInit(): void {
    this.phoneNumber = this.data.myNumber
  }

  cancel(){
    this.dialogRef.close("false")

  }

  end()
  {
    this.dialogRef.close("true")
  
  }

  close()
  {
    this.dialogRef.close("false")
  }
}
