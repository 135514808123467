import { Component, OnInit ,ViewChild ,ElementRef, NgZone, AfterViewInit, Output, EventEmitter, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators ,FormBuilder } from '@angular/forms';
import { Address, Payment, UserEditProfile } from 'src/app/models/user';
import { Payments } from 'src/app/models/payments';
import { UserService } from 'src/app/services/user-service';
import { AppProxy } from 'src/app/services/app-proxy';
import { CreditCardService } from 'src/app/services/creditCard-service';
import { CreditCardObject } from 'src/app/models/creditCard';
import {MatDatepicker} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { OrdersService } from 'src/app/services/order-service';
import { MyOrder } from 'src/app/models/order';
import { MapsAPILoader } from '@agm/core';
import { DataService } from 'src/app/services/data.service';
import { BussinssService } from 'src/app/services/bussinss-service';
import { Restaurant } from 'src/app/models/restaurant';
import { LoaderComponent } from '../../loader/loader.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { IFrameCreditCardComponent } from '../iframe-credit-card/iframe-credit-card.component';
import { CardInputComponent } from '../card-input/card-input.component';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YY',
  },
  display: {
    dateInput: 'MM/YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-secure-payment',
  templateUrl: './secure-payment.component.html',
  styleUrls: ['./secure-payment.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class SecurePaymentComponent implements OnInit {
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()changeShowSearch: EventEmitter<number> = new EventEmitter<number>();
  anotherAddressImg='assets/images/Pay_Page_Chack_Box_Off.png';
  myAddressImg='assets/images/Pay_Page_Chack_Box_Off.png';
  isCreditPayment:boolean=true;
  cashPaymentImg='assets/images/Pay_Page_Chack_Box_Off.png';
  paymentCreditImg='assets/images/Pay_Page_Chack_Box_On.png';
  keepCardImg='assets/images/Pay_Page_Chack_Box_On.png';
  notKeepCardImg='assets/images/Pay_Page_Chack_Box_Off.png';
  isKeepCard=true;
  paymentForm: FormGroup;
  addressForm:FormGroup;
  paymentCard:Payment;
  valid:boolean=false;
  payments:Payments[];
  paymentsOn='assets/images/Pay_Page_Chack_Box_On.png';
  paymentsOff='assets/images/Pay_Page_Chack_Box_Off.png';
  creditCardObject:CreditCardObject;
  userEditProfile:UserEditProfile;
  date = new FormControl(moment());
  date1:string="";
  month:string="";
  year:string="";
  totalPrice:string;
  deliveryPrice: string;
  isDelivery=0;
  paymentBy=1;
  address:Address;
  isChangeAdress=0;
  order:MyOrder;
  title: string = 'AGM project';
  latitude: number;
  longitude: number;
  lat: string;
  lng: string;
  zoom = 1;
  address_: string;
  private geoCoder: google.maps.Geocoder;
  methodsPayment:Payment=new Payment;
  Address:string;
  citiesList: { id: number; name: string; }[];
  priceGiftCard=0;
  selectedValue: null;
  token:string=null;
  byGiftCard=false;
  myAddressText:string="רביבים";
  price2:number=50;
  payBy=false;
  note:string;
  progressBar = document.querySelector('.progress-bar');
  progress = 0;
  intervalId: NodeJS.Timeout;
  isClickPayment=false;
  showProgress=false;
  nvStreet="";
  restaurant:Restaurant=new Restaurant;
  inCash=false;
  onCredit=false;
  //lat = 22.2736308;
  long = 70.7512555;
  options={
    componentRestrictions:{
      country:["isr"]
    }
  };
  @ViewChild('search')
  public searchElementRef: ElementRef;
  dialogRef: MatDialogRef<IFrameCreditCardComponent>;
  @ViewChild('dp')
  public dateElementRef: ElementRef;
  isAnotherAddress:number=0;
  innerWidth:any;
  @ViewChild("cvv") cvvElement: ElementRef;
  @ViewChild("year") yearElement: ElementRef;
  @ViewChild("identity")identityElement:ElementRef;
  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.backPress();
  }

  ngAfterViewInit() {
          this.paymentForm.get("month").valueChanges
        .pipe(filter((value: string) => value.length === 2))
        .subscribe(() => {
          this.yearElement.nativeElement.focus();
          this.paymentForm.get("year").setValue("");
        });
  
      this.paymentForm.get("year").valueChanges
        .pipe(filter((value: string) => value.length === 2))
        .subscribe(() => this.cvvElement.nativeElement.focus());

        this.paymentForm.get("cvv").valueChanges
        .pipe(filter((value: string) => value.length === 3))
        .subscribe(() => this.identityElement.nativeElement.focus());


        
  }

    ngOnInit(): void {
    this.createForm();
    this.innerWidth = window.innerWidth;
    this.dataService.show.next(false);
    this.changeShowPrice.emit();
    if(localStorage.ADDRESS!="")
    {
      this.isAnotherAddress=2;
      this.anotherAddressImg='assets/images/Pay_Page_Chack_Box_On.png';
   this.myAddressImg='assets/images/Pay_Page_Chack_Box_Off.png';
    this.address.nvStreet=localStorage.ADDRESS;
    this.lat=localStorage.LAT;
    this.lng=localStorage.LONG;
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=res;
      this.deliveryPrice=this.order.DeliveryPrice;
     // if(this.order.IsAddress==0)
     // this.isAnotherAddress=2;
    });
    }

    if (localStorage.paymentBy)
    {
      this.paymentBy = +(localStorage.paymentBy)
    }
    else
    {
    this.paymentBy=1;
    localStorage.paymentBy = 1
    }

   
    

    this.price2=localStorage.PRICE_GIFTCARD;
    var southWest = new google.maps.LatLng( 29.490725, 34.732479);
    var northEast = new google.maps.LatLng( 30.123217, 35.144384 );
    var bangaloreBounds = new google.maps.LatLngBounds( southWest, northEast );
   
    this.citiesList = [
      {id: 1, name: "אילת"}
   ];
   this.selectedValue = null;
   this.isDelivery=localStorage.DELIVERY;
   this.mapsAPILoader.load().then(() => { 
    var options = {
      componentRestrictions: {country: "IL"},
      bounds: bangaloreBounds,
      strictBounds: true ,
     }; 
    this.geoCoder = new google.maps.Geocoder;
    if(this.searchElementRef!=null)
    {
    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement,options);
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        //get the place result
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        //set latitude, longitude and zoom
        this.lat = ""+(place.geometry.location.lat());
        this.lng =""+(place.geometry.location.lng());
        this.Address = (""+place.name);
        this.zoom = 12;
        this.isChangeAdress=1;
        this.orderService.getOrderDetails(false).subscribe((res:any)=>{
          this.order=res;
          this.note=this.order.Note;
          if(this.order.Note==null || this.order.Note=='')
          this.note=null;
        this.showProgress = true
          this.orderService.setUpdateOrder(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,null,
            null,Number(this.order.TotalPrice),1,this.note,0,this.Address,this.lat,this.lng,null,null,null,null,null,0).subscribe((res:any)=>{

              this.showProgress = false
         if(res.responseMessage=="the delivery out of bounds")
   {
     this.proxy.showError("הכתובת שלך מחוץ לטווח ","");  
     this.addressForm.value.nvStreet="";
     this.searchElementRef=null;
   }
   else if(this.paymentBy<=1)
   this.router.navigate(['../endOrder']);
   else  if(this.paymentBy==2)
   {
   this.router.navigate(['../giftCard']); 
   this.creaditCardService.IsPaymentByGiftCard=true;
   }
  else if(this.paymentBy==3)
  this.router.navigate(['../codeWorker']); 
  });
        });
      }); 
    });
  }
  });
   if(this.isDelivery!=1)
   {
   this.isAnotherAddress=1;

  }
  else
  {
  this.orderService.getOrderDetails(false).subscribe((res:any)=>{
    this.order=res;
 if(this.order.IsDelivery==1)
  {
  if(Number(this.order.Balance)<Number(Number(this.order.TotalPrice)+Number(this.order.DeliveryPrice)))
  this.isAnotherAddress=1;
  }
  else 
  if(Number(this.order.Balance)<Number(this.order.TotalPrice))
  this.isAnotherAddress=1;
});
  }
    this.userService.getUserDetails(1).subscribe((res:any)=>{
      this.userEditProfile=res;
      this.myAddressText="("+this.userEditProfile.userAddress+")";
    });
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=res;
      this.deliveryPrice=this.order.DeliveryPrice;
      let price:number=0;
      for(let i=0;i<this.order.list.length;i++)
{
  price+=Number(this.order.list[i].Price);
  for(let j=0;j<this.order.list[i].listAddition.length;j++)
  for(let m=0;m<this.order.list[i].listAddition[j].listA.length;m++)
  price+=Number(this.order.list[i].listAddition[j].listA[m].Price);
}
if( this.price2==undefined || this.price2==0  || Number(this.order.TotalPrice)==Number(this.order.Balance))
{
      this.totalPrice=this.order.TotalPrice;
      this.byGiftCard=false;
}
     else
     {
       this.byGiftCard=true;
       let allPrice=Number(this.order.TotalPrice)
       if(this.order.IsDelivery==1)
       allPrice=Number(this.order.TotalPrice)+Number(this.order.DeliveryPrice)
      
       let iPay= Number(Number(allPrice)-Number(this.order.Balance));
       var number = Number(Number(allPrice)-Number(iPay));
      this.totalPrice=" הזמנה: " +this.proxy.round(allPrice,2) +" ₪"+" שולם עד כה: "+this.proxy.round(Number(iPay),2)+" ₪"+" הסכום ליתרה: "+this.proxy.round(parseFloat(number.toFixed(2)),2);
      localStorage.PRICE_GIFTCARD=0;
    }
      this.deliveryPrice=this.order.DeliveryPrice;
      this.userService.getUserCreditCard().subscribe((res:any)=>{
        if(res!=null)
        {
      this.methodsPayment=res;
      this.token=this.methodsPayment.card_token;
      this.methodsPayment.card_cvv=null;

      //כאן naomi
    this.methodsPayment.CardNumber = this.cc_format(this.methodsPayment.CardNumber)
      this.paymentForm.get("month").setValue(this.methodsPayment.CardDate.slice(0,2));
      this.paymentForm.get("year").setValue(this.methodsPayment.CardDate.slice(2));
    this.date = new FormControl(moment(this.methodsPayment.CardDate,"MM/YY"));
    this.methodsPayment.CardDate = this.methodsPayment.CardDate.slice(0, 2) + "/" + this.methodsPayment.CardDate.slice(2);
    this.date1=this.methodsPayment.CardDate;
        }
        else
        this.date1="";
        this.bussinssService.getRestaurantDetails_(this.order.RestaurantID).subscribe((res:any)=>{
          this.restaurant=res;
            for(let i=0;i<this.restaurant.listSetting.length;i++)
            {
              if(this.restaurant.listSetting[i].Text=="מזומן")
              {
                if(this.restaurant.listSetting[i].Show==1)
                this.inCash=true;
                else
                this.inCash=false;
              }
              if(this.restaurant.listSetting[i].Text=="אשראי")
              if(this.restaurant.listSetting[i].Show==1)
                this.onCredit=true;
                else
                this.onCredit=false;
              }      
      });
      });
    });

    this.payments=[
      // {
      //   "text":"תשלום באשראי",
      //   "imageNumber":1
      // },
      {
        "text":"תשלום ב Gift Card",
        "imageNumber":0,
        "id" : 1,
      },
      {
        "text":"תשלום קוד עובד",
        "imageNumber":0,
        "id" : 2,
      },
    
  ];

if (this.detectIPhone())
{
  this.payments.push({"text":"תשלום ב",
        "imageNumber":0,
        "id" : 3,})
  }
  this.dataService.showSearch.next(false);
  this.changeShowSearch.emit();
  this.dataService.byFood.next(true);
  this.changeShowSearch.emit();



   switch (this.paymentBy){
      case 0 : this.cashPayment();break
      case 1 : this.creditPayment() ;break
      default : this.paymentsBy(this.paymentBy-2)
    }
  }
 

  detectIPhone(): boolean {
    const userAgent = window.navigator.userAgent;
    return /iPhone|iPod/.test(userAgent);
  }
   cc_format(value: string) {
   
     var parts = []
    for (let i=0, len=value.length; i<len; i+=4) {
      parts.push(value.substring(i, i+4))
    }
    if (parts.length) {
      return parts.join(' ')
    } else {
      return value
    }
  }
  

  onKey(event: any) { // without type info
    this.proxy.hideKeyboard(event);
  }
  
    onBlur()
{
 if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur();
  }
}

clearMonth(){
  this.paymentForm.get("month").setValue("");
}

clearYear(){
  this.paymentForm.get("year").setValue("");
}

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.year=""+normalizedYear.year();
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    this.month=""+Number(normalizedMonth.month()+1);
    datepicker.close();
    this.date.value._i="";
  }

  constructor(private bussinssService:BussinssService,private router: Router,private dataService:DataService, private userService:UserService, private proxy:AppProxy,private creaditCardService:CreditCardService
    ,private fb: FormBuilder ,private matDialog2:MatDialog ,private matDialog:MatDialog ,private orderService:OrdersService,private mapsAPILoader: MapsAPILoader,private ngZone: NgZone) {
      this.address=new Address();
    }

    openMap()
    {
      sessionStorage.pageBack="securePayment";
      this.router.navigate(['../map']); 
    }

    ngOnDestroy() {
      clearInterval(this.intervalId);
    }

    createForm() {
      this.addressForm = this.fb.group({ // create nested formgroup to pass to child
        street: [, [Validators.required]],
        nvEnter: [],
        nvfloor: [],
        nvNumberHoush: []});


        this.paymentForm = this.fb.group({
          nvCardholderName:  ["", Validators.required],
          nvCardNumber: ["",[Validators.required, Validators.minLength(19)]],
          month: ["", [Validators.required, Validators.maxLength(2)]],
          year: ["", [Validators.required, Validators.maxLength(2)]],
          cvv:  ["", [Validators.required, Validators.maxLength(3),Validators.minLength(3)]],
          identityNumber:  ["", [Validators.required, Validators.maxLength(9),Validators.minLength(9)]],
        });

        this.paymentForm.get("month").valueChanges
        .pipe(filter((value: string) => value.length === 2))
        .subscribe(() => {
          this.yearElement.nativeElement.focus();
          this.paymentForm.get("year").setValue("");
        });
  
      this.paymentForm.get("year").valueChanges
        .pipe(filter((value: string) => value.length === 2))
        .subscribe(() => this.cvvElement.nativeElement.focus());

        this.paymentForm.get("cvv").valueChanges
        .pipe(filter((value: string) => value.length === 3))
        .subscribe(() => this.identityElement.nativeElement.focus());
    }

  keepCard(){
if(this.isKeepCard==true)
{
  this.isKeepCard=false;
  this.keepCardImg='assets/images/Pay_Page_Chack_Box_Off.png'; 
}
else
{
  this.isKeepCard=true;
  this.keepCardImg='assets/images/Pay_Page_Chack_Box_On.png'; 
}
  }

  getOrderDetails()
  {
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=res;
      if(this.order.IsAddress==0)
      this.isAnotherAddress=2;
      let note=this.order.Note;
      if(this.order.Note==null || this.order.Note=='')
      note=null;
      let PaymentBy: number;
      if(this.paymentBy==1 ||this.paymentBy==4 )
      PaymentBy=0;
      else
      PaymentBy=1;
      let status=2;
      if(this.paymentBy>1 && this.paymentBy!= 4)
      status=1;
      let IsFromLink=0;
      if(sessionStorage.deeplink==this.order.RestaurantID && status==2)
      IsFromLink=1;
      if(this.order.OrderID!=0) 
      {
        if(this.isAnotherAddress==2)
        {
          if(this.byGiftCard==true)
          {
            this.Address=this.order.Address;
            this.lat=this.order.Lat+"";
            this.lng=this.order.Lng+"";
            if(this.paymentBy==2)
            {
              this.showProgress=false;
             this.matDialog2.closeAll();
            this.router.navigate(['../giftCard']); 
            this.creaditCardService.IsPaymentByGiftCard=true;
            }
           else if(this.paymentBy==3)
           {
            this.showProgress=false;
            this.matDialog2.closeAll();
           this.router.navigate(['../codeWorker']);  
           }
          }
          else if(this.byGiftCard==false){
            this.Address=this.addressForm.value.street;
          let entry=null;
          if(this.addressForm.value.nvEntet!='')
          entry=this.addressForm.value.nvEnter;
          let floor=null;
          if(this.addressForm.value.nvfloor!='')
          floor=this.addressForm.value.nvfloor;
          let homeNumber=null;
          if(this.addressForm.value.nvNumberHoush!='')
          homeNumber=this.addressForm.value.nvNumberHoush;
          if(!this.Address.includes(":"))
          {
       if(entry!=null)
       this.Address+=" כניסה: "+entry;
       if(floor!=null)
       this.Address+=" קומה: "+floor;
       if(homeNumber!=null)
       this.Address+=" דירה: "+homeNumber;
          }
        }
          // let card_mask=null;
          // let card_validity=null;
        
          // if(this.paymentBy==1)
          // {
          //   card_mask=this.paymentForm.value.nvCardNumber;
          // card_validity=this.date1.replace("/","");
          // }
          this.showProgress = true
            this.orderService.setUpdateOrder(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,PaymentBy,
               null,Number(this.order.Balance),status,note,0,this.Address,this.lat,this.lng,null,null,null,this.paymentForm.value.nvCardNumber,
               this.date1.replace("/",""),IsFromLink).subscribe((res:any)=>{
                this.showProgress = false
                localStorage.MENU_ID=0;
              
              //  if(this.isChangeAdress==1)
               
       if(res.responseMessage=="the delivery out of bounds")
     {
      this.matDialog2.closeAll();
       this.proxy.showError("הכתובת שלך מחוץ לטווח ","");  
       this.addressForm.value.nvStreet="";
       this.searchElementRef=null;

     }
               else if(this.paymentBy<=1)
               {
                this.router.navigate(['../endOrder']);
                this.showProgress=false;
               }
                else  if(this.paymentBy==2)
                {
                  this.matDialog2.closeAll();
                this.router.navigate(['../giftCard']); 
                this.creaditCardService.IsPaymentByGiftCard=true;
                this.showProgress=false;
                }
               else if(this.paymentBy==3)
               {
                this.matDialog2.closeAll();
               this.router.navigate(['../codeWorker']); 
               this.showProgress=false;
               }
              });
          }
          else
          {
            this.showProgress = true
            this.orderService.setUpdateOrder2(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,
              this.order.IsSitting,PaymentBy,status,note,1,IsFromLink).subscribe((res:any)=>{    
                this.showProgress = false      
              if(res.responseMessage=="the delivery out of bounds")
              {
                this.matDialog2.closeAll();
                this.proxy.showError("הכתובת שלך מחוץ לטווח ","");  
                this.addressForm.value.nvStreet="";
                this.searchElementRef=null;
                this.showProgress=false;
                this.isClickPayment=false;
              }
              else
              {
                localStorage.MENU_ID=0;
               this.showProgress=false;
               if(this.paymentBy<=1 || this.paymentBy == 4)
              {
                this.matDialog2.closeAll();
               this.router.navigate(['../endOrder']);
              }
               else if(this.paymentBy==2)
              {
                this.matDialog2.closeAll();
               this.router.navigate(['../giftCard']); 
               this.creaditCardService.IsPaymentByGiftCard=true;
              }
              else if(this.paymentBy==3)
              {
                this.matDialog2.closeAll();
              this.router.navigate(['../codeWorker']); 
              }
            }
             });
          }
        }
        else
        {
        this.showProgress = true
        this.orderService.setUpdateOrder2(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,PaymentBy
        ,status,note,1,IsFromLink).subscribe((res:any)=>{
          this.showProgress = false
            localStorage.MENU_ID=0;
            this.showProgress=false;
            if(this.paymentBy<=1)
            this.router.navigate(['../endOrder']);
            else  if(this.paymentBy==2)
            {
              this.matDialog2.closeAll();
            this.router.navigate(['../giftCard']); 
            this.creaditCardService.IsPaymentByGiftCard=true;
            }
           else if(this.paymentBy==3)
           {
            this.matDialog2.closeAll();
           this.router.navigate(['../codeWorker']); 
           }
          });
        }
  });
  }

  setUserCreditCard()
  {
    this.openLoader();
    if(this.innerWidth<=480)
    this.showProgress=true;
    if(this.paymentBy==1 || this.paymentBy==4)
    {
    let IsApplePay = false;
        if(this.paymentBy==1)
        {
      var last2 = this.year.slice(-2);
      if(this.month.length==1)
      this.month="0"+this.month;
      this.date1=this.month+last2;
      if(this.date.value._i!="")
      this.date1=this.date.value._i;
      this.date1=this.paymentForm.value.month+this.paymentForm.value.year;
        
        if(!this.paymentForm.value.nvCardNumber.startsWith("x"))
        this.token=null;
      }
      else //applepay
      {
       IsApplePay = true
       this.token=null;
      }
        //todo-------------------------------
        
       let credit = this.paymentForm.value.nvCardNumber.replaceAll(" ", "");


      this.userService.setUserCreditCard(true,this.proxy.md5_(this.proxy.SECRET_KEY+localStorage.USER_ID),credit,this.date1.replace("/",""),
      this.paymentForm.value.cvv,this.paymentForm.value.identityNumber,this.paymentForm.value.nvCardholderName,this.token,this.isKeepCard,true,Number(this.order.Balance),this.order.OrderID ,null, IsApplePay).subscribe((res:any)=>{
      if(res.error==true)
      {
        this.showProgress=false;
       // this.matDialog.closeAll();
        this.isClickPayment=false;
      }
      else
      {

        if (res.responseMessage == "OK")
        {
        this.getOrderDetails();
        }
        else{
          this.showProgress=false;
           
          this.dialogRef =  this.matDialog.open(IFrameCreditCardComponent,{data : {myurl:res.responseMessage} 
            ,  disableClose: true 
          } );
          this.dialogRef.afterClosed().subscribe(result => {
            //console.log('The dialog was closed');
            // Here you can handle what to do after the dialog is closed
            // For example, you can check the result or perform any necessary actions
            if (result == "true") 
              this.getOrderDetails();
            else
              this.isClickPayment=false;
            
          })
        
        }
      }
       });
    }
   else if(this.paymentBy!=1 )
  {
    this.getOrderDetails();
  //   this.orderService.getOrderDetails(false).subscribe((res:any)=>{
  //     this.order=res;
  //    // this.router.navigate(['../restaurantCategory']);
  //     if(this.order.OrderID!=0) 
  //     {
  //       if(this.isAnotherAddress)
  //       {
  //         this.orderService.setUpdateOrder(this.order.OrderID,0,0,0,
  //           null,0,2,this.order.Note,0,this.addressForm.value.nvStreet,this.addressForm.value.nvHouseNumber,this.addressForm.value.nvfloor,
  //           this.addressForm.value.nvEntry,this.lat,this.lng,null,null,null).subscribe((res:any)=>{
  //             localStorage.MENU_ID=0;
  //             this.router.navigate(['../endOrder']);
  //           });
  //       }
  //       else
  //       this.orderService.setUpdateOrder(this.order.OrderID,0,0,0,
  //         null,0,2,this.order.Note,1,null,null,null,null,null,null,null,null,null).subscribe((res:any)=>{
  //           localStorage.MENU_ID=0;
  //           this.router.navigate(['../endOrder']);
  //         });
      
  //       }
  // });
}
      //  else if(this.paymentBy==2)
      //  {
      //    this.creaditCardService.IsPaymentByGiftCard=true;
      //  this.router.navigate(['../giftCard']); 
      //  }
      //  else if(this.paymentBy==3)
      //  this.router.navigate(['../codeWorker']); 
    // this.userService.setUserCreditCard(false,this.proxy.md5_(this.proxy.SECRET_KEY+this.userEditProfile.IDUser),localStorage.ACCESS_TOKEN,this.paymentForm.value.nvCardNumber,this.paymentForm.value.nvValidity,
    // this.paymentForm.value.nvCardholderName);
   // });
  }

   updateAddress()
  {

  }

  openLoader()
{
  this.showProgress=true;
  //this.matDialog2.open(LoaderComponent, { disableClose: true });
}

  payment()
  {
    if(this.isClickPayment==false)
    {
     
      if(this.isAnotherAddress>0)
      {
      
    this.isClickPayment=true;
    this.valid=true;
    if(this.isCreditPayment==true)
    {
    if(this.paymentForm.valid)
  this.setUserCreditCard();
  else
    {
  this.isClickPayment=false;
  this.proxy.showError("עליך למלא את כל פרטי האשראי","");  
    }
  }
  else if(this.isAnotherAddress==2)
  {
    if(this.addressForm.valid)
  this.setUserCreditCard();
  else
  {
  this.isClickPayment=false;
  this.proxy.showError("עליך למלא את כל פרטי האשראי","");  
  }
}
  else
  this.setUserCreditCard();
}
else
{
  //this.matDialog2.closeAll();
  this.showProgress=false;
this.proxy.showError("עליך לבחור כתובת למשלוח","");  
}
    }
}

  backPress()
  {
    this.router.navigate(['../yourOrder']);
  }

  
  creditPayment()
  {
    this.paymentBy=1;
    localStorage.paymentBy = this.paymentBy;
this.isCreditPayment=true;
this.paymentCreditImg='assets/images/Pay_Page_Chack_Box_On.png';
this.cashPaymentImg='assets/images/Pay_Page_Chack_Box_Off.png';
for(let j=0;j<this.payments.length;j++)
{
  this.payments[j].imageNumber=0;
}
  }

  cashPayment()
  {
    this.paymentBy=0;
    localStorage.paymentBy = this.paymentBy;
this.isCreditPayment=false;
this.cashPaymentImg='assets/images/Pay_Page_Chack_Box_On.png';
this.paymentCreditImg='assets/images/Pay_Page_Chack_Box_Off.png';
for(let j=0;j<this.payments.length;j++)
{
  this.payments[j].imageNumber=0;
}
  }

  paymentsBy(i: number)
  {
    this.cashPaymentImg='assets/images/Pay_Page_Chack_Box_Off.png';
    this.paymentCreditImg='assets/images/Pay_Page_Chack_Box_Off.png';
for(let j=0;j<this.payments.length;j++)
{
  this.payments[j].imageNumber=0;
}
this.payments[i].imageNumber=1;
this.isCreditPayment=false;
 //if(i==1)
 this.paymentBy=i+2;
 localStorage.paymentBy = this.paymentBy;
// {
//   if(this.isAnotherAddress==false)
//   this.router.navigate(['../giftCard']); 
//   else if(this.addressForm.valid)
//   this.router.navigate(['../giftCard']); 
// }
// else if(i==2)
// {
//   this.router.navigate(['../codeWorker']); 
// }
  }

  anotherAddress()
  {
    localStorage.ADDRESS="";
    localStorage.ADDRESS2="";
    this.addressForm.value.street="";
    this.address.nvStreet="";
   this.isAnotherAddress=2;
   this.anotherAddressImg='assets/images/Pay_Page_Chack_Box_On.png';
   this.myAddressImg='assets/images/Pay_Page_Chack_Box_Off.png';
  }

  myAddress()
  {
    this.isAnotherAddress=1;
    this.myAddressImg='assets/images/Pay_Page_Chack_Box_On.png'
    this.anotherAddressImg='assets/images/Pay_Page_Chack_Box_Off.png';
  }

  isValidInput(fieldName: string | number): boolean {
    if(this.valid==true)
    return this.paymentForm.controls[fieldName].invalid ;
    // && (this.registerForm.controls[fieldName].dirty || this.registerForm.controls[fieldName].touched)
    ;
  }

  isValidInputAddress(fieldName: string | number): boolean {
    if(this.valid==true)
    return this.addressForm.controls[fieldName].invalid 
    // && (this.registerForm.controls[fieldName].dirty || this.registerForm.controls[fieldName].touched)
    ;
  }


}
