import { Injectable } from '@angular/core';
import { Payment, UserEditProfile } from '../models/user';
import { AppProxy, HttpMethod } from "./app-proxy";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient} from "@angular/common/http";
import { ServerResponse } from 'http';
import {MatDialog } from '@angular/material/dialog';
import { LoginPhoneVarificationComponent } from '../components/login-phone-varification/login-phone-varification.component';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { GiftCards } from '../models/giftCard';

@Injectable(
{ providedIn: "root"}
)
export class UserService {
    public  LoginOBJ: boolean=false;
    public token=null;
    public userEditProfile:UserEditProfile;
    public payment:Payment;
    public giftCard:GiftCards[]=[];
    public giftCardFor:string;
    public state$ = new BehaviorSubject<any>("התנתק");
    innerWidth:any;


    constructor(private proxy: AppProxy,private router: Router,private http: HttpClient,private matDialog:MatDialog) 
    { 
      this.innerWidth = window.innerWidth;
    }

 login(userEmail:String, userPassword:String)
 {
  return new Observable(observer => {
  this.LoginOBJ=true;
  let name=this.proxy.md5_(this.proxy.SECRET_KEY + userEmail);
  let pass=this.proxy.md5_(this.proxy.SECRET_KEY + userPassword);

  //let url= this.proxy.baseQAUrl+"User/signIn?"+"signature="+name+"&userEmail="+userEmail+"&userPassword="+pass;
  //if(this.proxy.baseQAUrl=="https://test.baliapp.co.il/api/")
   let url= this.proxy.baseQAUrl+"User/signIn?"+"signature="+name+"&userCredential="+userEmail+"&userPassword="+pass;
   this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
     .subscribe(
       result => {
           let res: any = result;
           if (res.error==false)
            {
             let resList: ServerResponse = res.responseMessage;
             this.proxy.ACCESS_TOKEN=resList;
             localStorage.outOrIn="התנתק"
             sessionStorage.ACCESS_TOKEN = resList;
             localStorage.USER_FULL_NAME = userEmail;
             observer.next(resList);
           }
           else this.proxy.showError("שם משתמש או סיסמא שגויים","");
         },
       error => console.log('oops', error));
        });
 }

 register(formData:FormData)
{
  let url= this.proxy.baseQAUrl+"User/signUp";
  return new Observable(observer => {
  this.proxy.executePost<ServerResponse>(url,formData,HttpMethod.Post)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: ServerResponse = res;
            localStorage.outOrIn="התנתק"
            observer.next(resList);
          }
          else if(res.responseMessage=="phone already exists")
          this.proxy.showError("הטלפון כבר קיים במערכת","");
          else if(res.responseMessage=="email already exists")
          this.proxy.showError("האימייל כבר קיים במערכת","");
        },
      error => console.log('oops', error));
  });
 }

 setUserDetails(formData:FormData)
 {
  let url= this.proxy.baseQAUrl+"User/setUserDetails";
  return new Observable(observer => {
  this.proxy.executeUserPut<ServerResponse>(url,formData,HttpMethod.Put)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: ServerResponse = res;
            observer.next(resList);
          }
          else this.proxy.showError(res.message,"");
        },
      error => console.log('oops', error));
  });
 }

getUserDetails(operatingSystemType:number)
{
  let url= this.proxy.baseQAUrl+"User/getUserDetails?operatingSystemType="+operatingSystemType;
  return new Observable(observer => {
  this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: UserEditProfile = res;
            if(resList!=null)
            {
            this.userEditProfile=resList;
            localStorage.USER_ID=this.userEditProfile.IDUser;
            observer.next(this.userEditProfile);
            }
            else this.proxy.showError("אין לך פרטי משתמש","");
          }
        },
      error => console.log('oops', error));
  });
}

setContactRequest(FullName:string,Email:string,Phone:string,Message:string,userOrOneTimeOrder:string)
{
  let url= this.proxy.baseQAUrl+userOrOneTimeOrder+"/setContactRequest?FullName="+FullName+"&Email="+Email+"&Phone="+Phone
  +"&Message="+Message;
  this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: UserEditProfile = res;
            this.router.navigate(['mainHome']);
          }
          else this.proxy.showError("שגיאה ביצירת קשר","");
        },
      error => console.log('oops', error));
}

setUserCreditCard(editCard:boolean,signature:string,card_mask:string,card_validity:string,card_cvv:string,user_identity:string,invoice_name:string,token:string ,IsSave:boolean,
  IsPayment:boolean,TotalPrice:number,OrderID:number , GiftCardPhone : string = "" ,IsApplePay :boolean = false)
 {
  const _GiftCardPhone = GiftCardPhone == "" ? "" : '&GiftCardPhone=' +GiftCardPhone
   //console.log("שילמתי");
   let url="";
   if(token==null)
   {
   if(OrderID!=null)
   {
   url= this.proxy.baseQAUrl+"User/setUserCreditCard?signature="+signature+
   "&card_mask="+card_mask+"&card_validity="+card_validity+"&card_cvv="+card_cvv+"&user_identity="+user_identity+"&invoice_name="+invoice_name+"&IsSave="+IsSave+"&IsPayment="+IsPayment
   +"&TotalPrice="+TotalPrice+"&OrderID="+OrderID+_GiftCardPhone+"&IsApplePay="+IsApplePay ;
   }
   else
   url= this.proxy.baseQAUrl+"User/setUserCreditCard?signature="+signature+
   "&card_mask="+card_mask+"&card_validity="+card_validity+"&card_cvv="+card_cvv+"&user_identity="+user_identity+"&invoice_name="+invoice_name+"&IsSave="+IsSave+"&IsPayment="+IsPayment
   +"&TotalPrice="+TotalPrice+"&IsApplePay="+IsApplePay;
  }
   else if(OrderID!=null)
    url= this.proxy.baseQAUrl+"User/setUserCreditCard?signature="+signature+
   "&card_mask="+card_mask+"&card_validity="+card_validity+"&card_cvv="+card_cvv+"&user_identity="+user_identity+"&invoice_name="+invoice_name+"&token="+token+"&IsSave="+IsSave+"&IsPayment="+IsPayment
   +"&TotalPrice="+TotalPrice+"&OrderID="+OrderID+"&IsApplePay="+IsApplePay ;
   else
   url= this.proxy.baseQAUrl+"User/setUserCreditCard?signature="+signature+
   "&card_mask="+card_mask+"&card_validity="+card_validity+"&card_cvv="+card_cvv+"&user_identity="+user_identity+"&invoice_name="+invoice_name+"&token="+token+"&IsSave="+IsSave+"&IsPayment="+IsPayment
   +"&TotalPrice="+TotalPrice+"&IsApplePay="+IsApplePay ;
   return new Observable(observer => {
   this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
     .subscribe(
       result => {
           let res: any = result;
           if (res.error==false)
            {
             let resList: UserEditProfile = res;
            
             if(editCard==false)
             this.router.navigate(['delivery']);
           }
           else  if(res.responseMessage=="transaction failed")
           this.proxy.showError("סירוב כרטיס אשראי","");
           else
           this.proxy.showError(res.responseMessage,"");
           observer.next(res);
         },
       error => 
       {
         //console.log('oops', error)
         this.proxy.showError("שגיאה בפרטי אשראי","");
     });
     
   });
 }

getUserCreditCard()
{
  
  let url= this.proxy.baseQAUrl+"User/getUserCreditCard";
  return new Observable(observer => {
  this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: Payment = res;
            if(resList!=null)
            {
            this.payment=resList;
            observer.next(this.payment);
            }
            else this.proxy.showError("אין לך פרטי אשראי ששמורים במערכת","");
          }
        },
      error => console.log('oops', error));
  });
}

setUserPhone(userPhone:string)
{
  let url= this.proxy.baseQAUrl+"User/setUserPhone?userPhone="+userPhone;
  return new Observable(observer => {
  this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
          observer.next(res);
        
          
          }
          else this.proxy.showError("Sms שגיאה בשליחת קוד ל ","");
        },
      error => console.log('oops', error));
  });
}

reSendSmsCode()
{
  let url= this.proxy.baseQAUrl+"User/reSendSmsCode";
  return new Observable(observer => {
  this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
          observer.next(res);
          this.proxy.showSucceeded("Sms קוד חדש נשלח ב","");
          }
          else this.proxy.showError("Sms שגיאה בשליחת קוד ל ","");
        },
      error => console.log('oops', error));
  });
}

setUserSmsConfirmation(userSMSCode:string,innerWidth:any)
{
  let url= this.proxy.baseQAUrl+"User/setUserSmsConfirmation?userSMSCode="+userSMSCode;
  return new Observable(observer => {
  this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
    .subscribe(
      result => {
          let res: any = result;
          if(innerWidth<=480)
          {
           if (res.error==false && res.responseMessage=="ok")
            {
              observer.next(res);
            }
          else
          {
       //   this.proxy.showError("קוד שגוי הזן שנית","");
          // this.router.navigate(["/loginPhoneVarification"]);
           observer.next(res);
          }
          }
          else
          observer.next(res);
        },
      error => console.log('oops', error));
  });
}

resetPasswordSMSRequest(userPhone:string)
{
  let url= this.proxy.baseQAUrl+"User/resetPasswordSMSRequest?userPhone="+userPhone;
  return new Observable(observer => {
  this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
    .subscribe(
      result => {
          let res: any = result;
          observer.next(res);
          if (res.error==false && res.responseMessage=="ok")
           {
          }
        },
      error => console.log('oops', error));
  });
}

DeleteCreditCard()
{
  let url= this.proxy.baseQAUrl+"User/DeleteCreditCard";
  this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: UserEditProfile = res;
            this.router.navigate(['mainHome']);
          }
          else this.proxy.showError("שגיאה במחיקת פרטי אשראי","");
        },
      error => console.log('oops', error));
}

setGiftCard(SumMoney:number,Phone:string,CheckPhone:number,card_mask:string,card_validity:string	)
{
  let url;
  if(Phone!=null)
  url= this.proxy.baseQAUrl+"User/setGiftCard?SumMoney="+SumMoney+"&Phone="+Phone+"&CheckPhone="+CheckPhone
  +"&card_mask="+card_mask+"&card_validity="+card_validity;
  else
  url= this.proxy.baseQAUrl+"User/setGiftCard?SumMoney="+SumMoney+"&card_mask="+card_mask+"&card_validity="+card_validity;
  return new Observable(observer => {
  this.proxy.executePut<ServerResponse>(url,HttpMethod.Put)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: string = res;
            this.giftCardFor=res.responseMessage;
            observer.next(this.giftCardFor);
            // this.router.navigate(['giftCardSucsess']);
          }
          else
          { 
            this.proxy.showError("המספר אינו קיים במערכת","");
            observer.next(null);
        }
         
        },
      error => console.log('oops', error));
  });
}

getGiftCards()
{
  let url= this.proxy.baseQAUrl+"User/getGiftCards";
  return new Observable(observer => {
  this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
  .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
              let resList: GiftCards[] = res.list;
              this.giftCard=resList;
            if(this.giftCard.length>0)
            observer.next(this.giftCard);
            else this.proxy.showError("אין לך כרטיסי גיפט קארד","");
          }
          else this.proxy.showError("שגיאה בקבלת רשימת כרטיסי Gift Card","");
        },
      error => console.log('oops', error));
  }); 
}

openDialog(): void {
  const dialogRef = this.matDialog.open(LoginPhoneVarificationComponent, {
    backdropClass: 'cdk-overlay-transparent-backdrop',
    hasBackdrop: false,
  });
}
}