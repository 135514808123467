import { Component, OnInit ,EventEmitter ,Input ,Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CategoryItem ,Category} from 'src/app/models/category';
import { MyOrder } from 'src/app/models/order';
import { OpenningHours } from 'src/app/models/restaurant';
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { DataService } from 'src/app/services/data.service';
import { OrdersService } from 'src/app/services/order-service';
import { PopupEnterComponent } from '../popup-enter/popup-enter.component';
import { PopupEnter2Component } from '../popup-enter2/popup-enter2.component';
import { PopupOrderComponent } from '../popup-order/popup-order.component';

@Component({
  selector: 'app-resraurant-item',
  templateUrl: './resraurant-item.component.html',
  styleUrls: ['./resraurant-item.component.scss']
})
export class ResraurantItemComponent implements OnInit {
  stars:number[]=[1,2,3,4,5];
  @Input() categoryItem:CategoryItem;
  @Input() category:Category;
  @Input() indexList:number;
  @Input() restaurantCategory:number;
  @Output()
  change: EventEmitter<number> = new EventEmitter<number>();
  categoryType:number=1;
  clickFevorite=false;
  fevorit='assets/images/Restorant_Page_Fevorit_Button.png';
  fevoritActive='assets/images/Fevorit_Activ.png';
  order:MyOrder;
  localId;
  isClick=false;
  Rate= new Array<number>(3);
  openningHours:OpenningHours[]=[];
  dayTime="";
  innerWidth:any;

        constructor(private router: Router,private dataService:DataService ,private matDialog: MatDialog, private proxy: AppProxy,private bussinssService: BussinssService ,private orderService:OrdersService) {  }

  onClickCategory(CategoryNumber:number)
  {
    sessionStorage.deeplink2='';
    sessionStorage.deeplink='';
    localStorage.TEXT=""
    this.isClick=true;
    if(localStorage.SMS=="")
   this.proxy.showError("חובה להכניס קוד","");
    if(sessionStorage.ACCESS_TOKEN  == "null" || localStorage.SMS_CODE=="") 
      
  {
    if (this.innerWidth <= 480)
    this.matDialog.open(PopupEnter2Component);
  else
    this.matDialog.open(PopupEnterComponent);
  }
    else
    {
      localStorage.PRICE=0;
  if(this.clickFevorite==false)
  {
    let id;
      if(this.categoryItem.ID>0)
      id=this.categoryItem.ID;
      else
      id=this.categoryItem.RstaurantID;
      this.bussinssService.getOpenningHours2(id).subscribe((res:boolean)=>{
        if(this.categoryItem.RstaurantID==null )
      this.dayTime="";
      else
      {
       
        if (res == false)
          {
          this.dayTime="סגור"
          }
        }
        if(this.dayTime=="סגור")
        this.proxy.showError("המסעדה סגורה\n אין אפשרות לבצע הזמנה","");
       //this.openDialog(); 
        else{
    this.orderService.restaurant=null;
    let id=this.categoryItem.ID;
    // if(this.categoryItem.RstaurantID==null )

    if(CategoryNumber==1 || this.restaurantCategory==8)
    {
     // localStorage.ID=id;
       this.orderService.getOrderDetails(false).subscribe((res:any)=>{
         this.order=res;
         if(this.order.OrderID==0 && (this.order.list==null || this.order.list.length==0)) 
         {
          localStorage.MENU_ID=0;
          this.orderService.order=null;
          sessionStorage.CATEGORY_FILTER="";
          localStorage.ID=id;
          this.dataService.endToppings.next(true);
          this.change.emit();
          let url: string = "/restaurant/" + this.categoryItem.NameEn
          this.router.navigateByUrl(url);
        //this.router.navigate(['../restaurant']);
         }
    else
    {
      if(this.order.list==null || this.order.list.length==0 )
              {
              this.orderService.setUpdateOrder(this.order.OrderID,0,0,0,1,
              null,null,0,null,1,null,null,null,null,null,null,null,null,0).subscribe((res:any)=>{
                this.orderService.order=null;
                sessionStorage.CATEGORY_FILTER="";
                localStorage.ID=id;
                this.dataService.endToppings.next(true);
                this.change.emit();
                let url: string = "/restaurant/" + this.categoryItem.NameEn
                this.router.navigateByUrl(url);
             // this.router.navigate(['../restaurant']);
                 });
              }
              else
            {
              localStorage.ID=this.categoryItem.ID
      this.matDialog.open(PopupOrderComponent, {
        data: { OrderID: this.order.OrderID },
      });
    }
  }
  });
      
    }
    else if(CategoryNumber==2)
    {
     // localStorage.ID=id;
     localStorage.ID_CATEGORY=id;
      let url: string = "/restaurantCategory/" + id
      this.router.navigateByUrl(url);
      //this.router.navigate(['../restaurantCategory']);
    }
  }
});}}}

  ngOnInit(): void {
    this.innerWidth=window.innerWidth;
   // sessionStorage.deeplink='';
    this.localId=localStorage.ID;
    this.clickFevorite=false;
    if(this.categoryItem !=null && this.categoryItem.ImageFile!=null)
     if(this.category.categoryType==2)
    this.categoryType=2;
    else
    this.categoryType=1;
    if(this.categoryItem.IsFevorite==1)
    this.fevorit='assets/images/Fevorit_Activ.png';
    else
    this.fevorit='assets/images/Restorant_Page_Fevorit_Button.png';
    if(this.categoryItem.Rate==0 ||this.categoryItem.Rate==null)
    this.categoryItem.Rate=3;
  }

  addFevorit()
  {
    if(sessionStorage.ACCESS_TOKEN  == "null"  || localStorage.SMS_CODE=="")
    this.router.navigate(['../mainHome']);
    else
    {
    this.clickFevorite=true;
   this.bussinssService.setFavoriteBusiness(this.categoryItem.ID).subscribe((res:any)=>{
      this.change.emit();
  });
}
}
}

