import { MapsAPILoader } from '@agm/core';
import { LocationStrategy } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { filter } from 'rxjs/operators';
import { CreditCardObject } from 'src/app/models/creditCard';
import { MyOrder } from 'src/app/models/order';
import { Payments } from 'src/app/models/payments';
import { Restaurant } from 'src/app/models/restaurant';
import { Address, Payment, UserEditProfile } from 'src/app/models/user';
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { CreditCardService } from 'src/app/services/creditCard-service';
import { DataService } from 'src/app/services/data.service';
import { OneTimeOrderService } from 'src/app/services/oneTimeOrder.service';
import { OrdersService } from 'src/app/services/order-service';
import { LoaderComponent } from '../../loader/loader.component';
import { Map2Component } from '../map2/map2.component';
import { VerificationCodeComponent } from '../verification-code/verification-code.component';
import { IFrameCreditCardComponent } from '../iframe-credit-card/iframe-credit-card.component';
import { PopupIsCorectOrderComponent } from '../popup-isCorectOrder/popup-isCorectOrder.component';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YY',
  },
  display: {
    dateInput: 'MM/YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class PaymentComponent implements OnInit {
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()changePrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()progress: EventEmitter<number> = new EventEmitter<number>();
  anotherAddressImg='assets/images/Pay_Page_Chack_Box_On.png';
  myAddressImg='assets/images/Pay_Page_Chack_Box_Off.png';
  isCreditPayment:boolean=true;
  cashPaymentImg='assets/images/Pay_Page_Chack_Box_Off.png';
  applePayPaymentImg='assets/images/Pay_Page_Chack_Box_Off.png';
  paymentCreditImg='assets/images/Pay_Page_Chack_Box_On.png';
  keepCardImg='assets/images/Pay_Page_Chack_Box_On.png';
  notKeepCardImg='assets/images/Pay_Page_Chack_Box_Off.png';
  isKeepCard=true;
  paymentForm: FormGroup;
  //addressForm:FormGroup;
  paymentCard:Payment;
  valid:boolean=false;
  payments:Payments[];
  paymentsOn='assets/images/Pay_Page_Chack_Box_On.png';
  paymentsOff='assets/images/Pay_Page_Chack_Box_Off.png';
  creditCardObject:CreditCardObject;
  userEditProfile:UserEditProfile;
  date = new FormControl(moment());
  date1:string="";
  month:string="";
  year:string="";
  totalPrice:string;
  deliveryPrice;
  isDelivery=0;
  private paymentBy=1;
  address:Address;
  isChangeAdress=0;
  order:MyOrder;
  title: string = 'AGM project';
  latitude: number;
  longitude: number;
  lat: string;
  lng: string;
  zoom = 1;
  address_: string;
  private geoCoder;
  methodsPayment:Payment=new Payment;
  Address:string;
  AddressMe:string;
  citiesList;
  priceGiftCard=0;
  selectedValue;
  token:string=null;
  byGiftCard=false;
  myAddressText:string="רביבים";
  price2:number=50;
  payBy=false;
  note:string;
  progressBar = document.querySelector('.progress-bar');
  //progress = 0;
  intervalId;
  isClickPayment=false;
  showProgress=false;
  restaurant:Restaurant=new Restaurant;
  inCash=false;
  onCredit=false;
    myAdd = "";
  options={
    componentRestrictions:{
      country:["isr"]
    }
  };
  @ViewChild('search')
  public searchElementRef: ElementRef;

  @ViewChild('dp')
  public dateElementRef: ElementRef;
  isAnotherAddress:boolean=true;
  IsDelivery=1;
  innerWidth:any;
  isIphone = false;
  @ViewChild("cvv") cvvElement: ElementRef;
  @ViewChild("month") monthElement: ElementRef;
  @ViewChild("year") yearElement: ElementRef;
  @ViewChild("identity")identityElement:ElementRef;
  dialogRef: MatDialogRef<VerificationCodeComponent>;
  dialogRef2: MatDialogRef<IFrameCreditCardComponent>;
  dialogRef3: MatDialogRef<PopupIsCorectOrderComponent>;
  text;
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   this.backPress();
  // }

  // @HostListener('window:popstate', ['$event'])
  // onBrowserBackBtnClose(event: Event) {
  //     //console.log('back button pressed');
  //     event.preventDefault(); 
  //     this.router.navigate(['yourOrder'],  {replaceUrl:true});
  // }

  constructor(location: LocationStrategy ,private router: Router, private dataService: DataService ,private matDialog:MatDialog, private bussinssService:BussinssService, private proxy:AppProxy,private creaditCardService:CreditCardService
    ,private fb: FormBuilder,private oneTimeOrderService:OneTimeOrderService,private orderService:OrdersService,private mapsAPILoader: MapsAPILoader,private ngZone: NgZone) {
      this.address=new Address();
      // location.onPopState(() => {
      //   //alert(window.location);
      //   this.router.navigate(["../yourOrder"])
      // });

      // history.pushState(null, null, window.location.href);
      // // check if back or forward button is pressed.
      // location.onPopState(() => {
      //     history.pushState(null, null, window.location.href);
      //     this.stepper.previous();
      // });
   // }
    }
 
  ngOnInit(): void {
    this.text = this.dataService.address.getValue() || {};
    this.dataService.address.subscribe(data=>{
      this.address.nvStreet=localStorage.ADDRESS;
      this.lat=localStorage.LAT;
      this.lng=localStorage.LONG;

    });
    this.isClickPayment=false;
    this.dataService.show.next(false);
    this.changeShowPrice.emit();
    this.innerWidth = window.innerWidth;
    this.showProgress = this.dataService.show.getValue() || {};
    this.dataService.showProgress.subscribe(data=>{
      this.showProgress=data;
    });
 if(localStorage.DELIVERY==1)
 {
    this.createForm();
    this.place();
    this.IsDelivery=1;
 }
    else
    {
    this.createForm2();
    this.IsDelivery=0;
    }
    this.paymentBy=1;
    this.price2=localStorage.PRICE_GIFTCARD;
    this.selectedValue = null;
   this.isDelivery=0;
   if(localStorage.ADDRESS=="")
   if(localStorage.ADDRESS2!="")
   {
   localStorage.ADDRESS=localStorage.ADDRESS2;
   localStorage.LAT=localStorage.LAT2;
   localStorage.LONG=localStorage.LONG2;
   }
    if(localStorage.ADDRESS!=""  && localStorage.ADDRESS!=undefined && localStorage.ADDRESS!='undefined' && localStorage.ADDRESS!=null &&  localStorage.ADDRESS!="null")
    {
    this.address.nvStreet=localStorage.ADDRESS;
    this.lat=localStorage.LAT;
    this.lng=localStorage.LONG;
  
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=res;
      this.deliveryPrice=this.order.DeliveryPrice;
      this.totalPrice=this.order.TotalPrice;
      this.isDelivery=1;
    });
    }
    this.startLocal();
   if(this.isDelivery!=1)
   this.isAnotherAddress=false;
  else
  {
  this.orderService.getOrderDetails(false).subscribe((res:any)=>{
    this.order=res;
 if(this.order.IsDelivery==1)
  {
  if(Number(this.order.Balance)<Number(Number(this.order.TotalPrice)+Number(this.order.DeliveryPrice)))
  this.isAnotherAddress=false;
  }
  else 
  {
  if(Number(this.order.Balance)<Number(this.order.TotalPrice))
  this.isAnotherAddress=false;
  }
});
  }
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=res;
      let price:number=0;
      for(let i=0;i<this.order.list.length;i++)
{
  price+=Number(this.order.list[i].Price);
  for(let j=0;j<this.order.list[i].listAddition.length;j++)
  for(let m=0;m<this.order.list[i].listAddition[j].listA.length;m++)
  price+=Number(this.order.list[i].listAddition[j].listA[m].Price);
}
if( this.price2==undefined || this.price2==0  || this.price2==Number(this.order.Balance))
{
      this.totalPrice=this.order.TotalPrice;
      this.byGiftCard=false;
}
     else
     {
       this.byGiftCard=true;
       let allPrice=Number(this.order.TotalPrice)
       if(this.order.IsDelivery==1)
       allPrice=Number(this.order.TotalPrice)+Number(this.order.DeliveryPrice)
      
       let iPay= Number(Number(allPrice)-Number(this.order.Balance));
       var number = Number(Number(allPrice)-Number(iPay));
      this.totalPrice=" הזמנה: " +allPrice +" ₪"+" שולם עד כה: "+Number(iPay)+" ₪"+" הסכום ליתרה: "+parseFloat(number.toFixed(2));
      localStorage.PRICE_GIFTCARD=0;
    }
      this.deliveryPrice=this.order.DeliveryPrice;
      this.bussinssService.getRestaurantDetails_(this.order.RestaurantID).subscribe((res:any)=>{
        this.restaurant=res;
      
          for(let i=0;i<this.restaurant.listSetting.length;i++)
          {
          if(this.restaurant.listSetting[i].Text=="מזומן")
          {
            if(this.restaurant.listSetting[i].Show==1)
              {
            this.inCash=true;
          
              }
            else
            {
            this.inCash=false;
           
            }
          }
          if(this.restaurant.listSetting[i].Text=="אשראי")
          if(this.restaurant.listSetting[i].Show==1)
            this.onCredit=true;
            else
            this.onCredit=false;
          }      
    });
  });

    this.payments=[
      {
        "text":"תשלום ב Gift Card",
        "imageNumber":0,
        "id" : 1,
      },
      {
        "text":"תשלום קוד עובד",
        "imageNumber":0,
        "id" : 2,
      },
  ];
  this.dataService.showSearch.next(false);
  this.changByFood.emit();
  this.dataService.byFood.next(true);
  this.changByFood.emit();
  this.isIphone = this.detectIPhone()

  }

  detectIPhone(): boolean {
    const userAgent = window.navigator.userAgent;
    return /iPhone|iPod/.test(userAgent);
  }

  onBlur()
  {
   if (document.activeElement instanceof HTMLElement) 
      document.activeElement.blur();
  }

  onKey(event: any) { // without type info
    this.proxy.hideKeyboard(event);
  }

    chosenYearHandler(normalizedYear: Moment) {
      const ctrlValue = this.date.value;
      ctrlValue.year(normalizedYear.year());
      this.year=""+normalizedYear.year();
      this.date.setValue(ctrlValue);
    
    }
  
    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
      const ctrlValue = this.date.value;
      ctrlValue.month(normalizedMonth.month());
      this.date.setValue(ctrlValue);
      this.month=""+Number(normalizedMonth.month()+1);
      datepicker.close();
      this.date.value._i="";
      var last2 = this.year.slice(-2);
      if(this.month.length==1)
      this.month="0"+this.month;
      this.date1=this.month+last2;
      this.paymentForm.value.date=this.date1;
      this.date1=this.paymentForm.value.month+"/"+this.paymentForm.value.year;
      localStorage.date=this.date1;
    }
  
  //   backPress()
  //   {
  //     //if(sessionStorage.deeplink!="")
  //     //window.history.back();
  //     //else
  //     {
  //     // let page=location.href;
  //     // let a=page.split('/')[3]
  //     localStorage.back="true";
  //     sessionStorage.backYourOrder="false";
  //     let a=location.pathname;
  //     if(a=="/map" && this.isClickPayment==true)
  //   {
  //     this.matDialog.closeAll();
  //     localStorage.ADDRESS=localStorage.ADDRESS2;
  //     this.router.navigate(['../payment']);
  //   }
  //     else
  //     {
  //       let url: string = "../../yourOrder";
  //       this.router.navigateByUrl(url);
  //     }

  //   }
  // }

  backPress()
  {
    // let page=location.href;
    // let a=page.split('/')[3]
    let a=location.pathname;
  //   if(a=="/map" && this.isClickPayment==true)
  // {
  //   this.matDialog.closeAll();
  //   localStorage.ADDRESS=localStorage.ADDRESS2;
  //   this.router.navigate(['../../payment']);
  // }
  //   else
    this.router.navigate(['../yourOrder']);
  }

    ngOnDestroy() {
      clearInterval(this.intervalId);
    }

    ngAfterViewInit() {
      this.paymentForm.get("month").valueChanges
    .pipe(filter((value: string) => value.length === 2))
    .subscribe(() => {
      this.yearElement.nativeElement.focus();
      this.paymentForm.get("year").setValue("");
    });

  this.paymentForm.get("year").valueChanges
    .pipe(filter((value: string) => value.length === 2))
    .subscribe(() => this.cvvElement.nativeElement.focus());

    this.paymentForm.get("cvv").valueChanges
    .pipe(filter((value: string) => value.length === 3))
    .subscribe(() => this.identityElement.nativeElement.focus());
}

    createForm() {

      this.paymentForm = this.fb.group({ // create nested formgroup to pass to child
        nvCardholderName: [, [Validators.required]],
        nvCardNumber: ["",[Validators.required, Validators.minLength(19)]],
        month: ["", [Validators.required, Validators.maxLength(2)]],
        year: ["", [Validators.required, Validators.maxLength(2)]],
        cvv: ["", [Validators.required, Validators.maxLength(3)]],
        identityNumber: ["", [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
        nvOrderName: [, [Validators.required]],
        nvStreet: ["", [Validators.required]],
        nvEnter: [""],
        nvfloor: [""],
        nvNumberHoush:[""],
        nvPhone:["", [Validators.required,Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")]],
        nvEmail: ["", [Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    });

    this.paymentForm.get("month").valueChanges
    .pipe(filter((value: string) => value.length === 2))
    .subscribe(() => 
    {
      this.yearElement.nativeElement.focus();
      this.paymentForm.get("year").setValue("");
    });


      this.startLocal2();
    }

    createForm2() {
      this.paymentForm = this.fb.group({ 
        nvCardholderName: ["", Validators.required],
        nvCardNumber: ["",[Validators.required, Validators.minLength(19)]],
        month: ["", [Validators.required, Validators.maxLength(2)]],
        year: ["", [Validators.required, Validators.maxLength(2)]],
         cvv: ["", [Validators.required, Validators.maxLength(3)]],
         identityNumber: ["",[Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
         nvOrderName: ["", Validators.required],
         nvEmail: ["", [Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
         nvPhone: ["", [Validators.required,Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")]],
      });

      this.paymentForm.get("month").valueChanges
      .pipe(filter((value: string) => value.length === 2))
      .subscribe(() => 
      {
        this.yearElement.nativeElement.focus();
        this.paymentForm.get("year").setValue("");
      });

    this.paymentForm.get("year").valueChanges
      .pipe(filter((value: string) => value.length === 2))
      .subscribe(() => this.cvvElement.nativeElement.focus());

      this.paymentForm.get("cvv").valueChanges
      .pipe(filter((value: string) => value.length === 3))
      .subscribe(() =>this.identityElement.nativeElement.focus());
      this.startLocal2();
    }

    createForm3() {
      this.paymentForm = this.fb.group({ // create nested formgroup to pass to child
        nvOrderName: [, [Validators.required]],
        nvStreet: [""],
        nvEnter: [""],
        nvfloor:[""],
        nvNumberHoush:[""],
        nvPhone: new FormControl("", [Validators.required,Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")]),
        nvEmail: new FormControl("", [Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    });
    if(localStorage.ADDRESS!="")
    {
     
      this.paymentForm.value.nvStreet=localStorage.ADDRESS;
      this.Address = localStorage.ADDRESS;
      if(localStorage.ADDRESS!=""  && localStorage.ADDRESS!=undefined && localStorage.ADDRESS!='undefined' && localStorage.ADDRESS!=null &&  localStorage.ADDRESS!="null")
    this.address.nvStreet=localStorage.ADDRESS;
    this.lat=localStorage.LAT;
    this.lng=localStorage.LONG;
     
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=res;
      this.deliveryPrice=this.order.DeliveryPrice;
      this.isDelivery=1;
    });
    
    }
   if(localStorage.phone!='undefined')
    this.address.nvPhone=localStorage.phone;
    if(localStorage.orderName!='undefined' && localStorage.orderName!='null')
    this.address.nvOrderName=localStorage.orderName;
    if(localStorage.email!='undefined')
    this.address.nvEmail=localStorage.email;
    if(localStorage.enter!='undefined')
    this.address.nvEnter=localStorage.enter;
    this.address.nvfloor=localStorage.floor;
    this.address.nvHouseNumber=localStorage.numberHoush;
    if(localStorage.identityNumber!='undefined'  && localStorage.identityNumber!=null)
    this.methodsPayment.UserIdentity=localStorage.identityNumber;
    if(localStorage.nvCardholderName!='undefined' && localStorage.nvCardholderName!=null)
    this.methodsPayment.UserName=localStorage.nvCardholderName;
    if(localStorage.nvCardNumber!='undefined')
    this.methodsPayment.CardNumber=localStorage.nvCardNumber;
    if(localStorage.cvv!='undefined' && localStorage.cvv!="")
   // this.methodsPayment.card_cvv=localStorage.cvv;
    this.paymentForm.controls["cvv"].setValue(localStorage.cvv);  
    if(localStorage.date!=undefined && localStorage.date!='undefined' && localStorage.date!="")

    {
      this.date = new FormControl(moment(localStorage.date,"MM/YY"));
      // this.paymentForm.controls['month'].setValue(localStorage.date.slice(0, 2)); 
      // this.paymentForm.controls['year'].setValue(localStorage.date.slice(2)); 
      let date=localStorage.date;
      date=date.replace("/","");
      this.paymentForm.controls["year"].setValue(date.slice(2));  
      this.paymentForm.controls["month"].setValue(date.slice(0,2));    
      // this.paymentForm.value.month.setValue(localStorage.date.slice(0, 2));
      // this.paymentForm.value.year.setValue(localStorage.date.slice(2));
      this.methodsPayment.CardDate = this.methodsPayment.CardDate.slice(0, 2) + "/" + this.methodsPayment.CardDate.slice(2);
      this.date1=this.methodsPayment.CardDate;
    }

    this.place();
    }

    localPayment()
    {
      localStorage.paymentBy=this.paymentBy;
     localStorage.nvCardNumber=this.paymentForm.value.nvCardNumber;
      localStorage.cvv=this.paymentForm.value.cvv;
     localStorage.identityNumber=this.paymentForm.value.identityNumber;
      localStorage.nvCardholderName=this.paymentForm.value.nvCardholderName;
      var last2 = this.year.slice(-2);
      if(this.month.length==1)
      this.month="0"+this.month;
      this.date1=this.month+last2;
      this.date1=this.paymentForm.value.month+"/"+this.paymentForm.value.year;
       localStorage.date=this.date1;
    }

    local(){

      if(this.paymentBy==1)
      this.localPayment();
      else
      localStorage.paymentBy=this.paymentBy;
      localStorage.phone=this.paymentForm.value.nvPhone;
       localStorage.orderName=this.paymentForm.value.nvOrderName;
       localStorage.email=this.paymentForm.value.nvEmail;
       localStorage.street=this.paymentForm.value.nvStreet;
       localStorage.enter=this.paymentForm.value.nvEnter;
       localStorage.floor=this.paymentForm.value.nvfloor;
       localStorage.numberHoush=this.paymentForm.value.nvNumberHoush;
    }

    clearMonth(){
      this.paymentForm.get("month").setValue("");
    }
    
    clearYear(){
      this.paymentForm.get("year").setValue("");
    }

  getOrderDetails()
  {
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=res;
      let note=this.order.Note;
      if(this.order.Note==null || this.order.Note=='')
      note=null;
      let PaymentBy;
      if(this.paymentBy==1)
      PaymentBy=0;
      else
      PaymentBy=1;
      let status=1;
      this.local();
      if(this.paymentBy==1)
      {
        var last2 = this.year.slice(-2);
        if(this.month.length==1)
        this.month="0"+this.month;
        this.date1=this.month+last2;
        this.date1=this.paymentForm.value.month+this.paymentForm.value.year;
      localStorage.date=this.date1;
      }
      if(this.order.OrderID!=0) 
      {
        if(this.IsDelivery==1)
        {
          {
            if(this.Address!=null && this.Address!=undefined)
            {
          let entry=null;
          if(this.paymentForm.value.nvEnter!='')
          entry=this.paymentForm.value.nvEnter;
          let floor=null;
          if(this.paymentForm.value.nvfloor!='')
          floor=this.paymentForm.value.nvfloor;
          let homeNumber=null;
          if(this.paymentForm.value.nvNumberHoush!='')
          homeNumber=this.paymentForm.value.nvNumberHoush;
          if(!this.Address.includes(":"))
          {
       if(entry!=null)
       this.Address+=" כניסה: "+entry;
       if(floor!=null)
       this.Address+=" קומה: "+floor;
       if(homeNumber!=null)
       this.Address+=" דירה: "+homeNumber;
          }
          let email=null;
          if(this.paymentForm.value.nvEmail!="")
          email=this.paymentForm.value.nvEmail;
            this.orderService.setUpdateOrder3(this.order.OrderID,this.paymentForm.value.nvOrderName,this.paymentForm.value.nvPhone,email,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,PaymentBy,
               Number(this.order.Balance),status,note,this.Address,this.lat,this.lng).subscribe((res:any)=>
               {
                localStorage.MENU_ID=0;
                this.showProgress=false;
                if(this.paymentBy!=undefined )
                localStorage.paymentBy=this.paymentBy;
             
              //  if(this.isChangeAdress==1)
       if(res.responseMessage=="the delivery out of bounds")
     {
       this.proxy.showError("הכתובת שלך מחוץ לטווח ","");  
       this.paymentForm.value.nvStreet="";
       this.searchElementRef=null;
       this.matDialog.closeAll();
     }
               else 
               {

                this.oneTimeOrderService.sendConfirmationSms(this.order.OrderID , +this.order.Balance  ,localStorage.paymentBy == 0 ? 1 : 0).subscribe((res:any)=>{
                //  this.matDialog.closeAll();
                //  if(this.innerWidth<=480)
              //  this.router.navigate(['../verificationCode']);
               // else
               this.openDialog(res);
                });
            //     this.showProgress=true
            //     if(this.paymentBy==1)
            //     {
            //         {
            //       var last2 = this.year.slice(-2);
            //       if(this.month.length==1)
            //       this.month="0"+this.month;
            //       this.date1=this.month+last2;
            //         }
            //         if(!this.paymentForm.value.nvCardNumber.startsWith("x"))
            //         this.token=null;
            //       this.orderService.setOrderPayment(this.paymentForm.value.nvCardNumber,this.date1.replace("/",""),
            //       this.paymentForm.value.cvv,this.paymentForm.value.identityNumber,this.paymentForm.value.nvCardholderName,Number(this.order.Balance))
            //       .subscribe((res:any)=>
            //       {
            //        if(res.error==true)
            //        {
            //         this.showProgress=false;
            //         this.isClickPayment=false;
            //         this.proxy.showError(res.responseMessage,"");
            //        }
            //        else
            //       {
                 
            //         if(this.order.IsDelivery==1)
                 
            //           this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2,this.Address,this.lat,this.lng).subscribe((res:any)=>
            //           {
            //                 this.router.navigate(['../endOrder']);
            //                });
              
            //                 else
            //                 this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2).subscribe((res:any)=>
            //                 {
            //        this.router.navigate(['../endOrder']);
            //         });
            //       }
            //         //this.getOrderDetails();
            //        });
            //     }
            //    else 
            //   // if(this.paymentBy!=1 )
            //   {
            //     if(this.order.IsDelivery==1)
            //     {
            //     this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2,this.Address,this.lat,this.lng).subscribe((res:any)=>
            //     {
            //           this.router.navigate(['../endOrder']);
            //          });
            //         }
            //           else
            //           this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2).subscribe((res:any)=>
            //           {
            //  this.router.navigate(['../endOrder']);
            //   }); 
            // }
               }
               //if(this.paymentBy<=1)        
               // this.setUserCreditCard();
        
               
              });
          }
        else{
          this.proxy.showError("הכתובת שלך מחוץ לטווח\n חובה לבחור כתובת מגוגל ","");  
          this.matDialog.closeAll();
          this.paymentForm.value.nvStreet="";
          this.searchElementRef=null;
          this.isClickPayment=false;
        }}}
          else
          {
            let email=null;
            if(this.paymentForm.value.nvEmail!="")
            email=this.paymentForm.value.nvEmail;
            this.orderService.setUpdateOrder22(this.order.OrderID,this.paymentForm.value.nvOrderName,this.paymentForm.value.nvPhone,email,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,PaymentBy,status,note,1).subscribe((res:any)=>
            {
               localStorage.MENU_ID=0;
               this.showProgress=false;
               {
                this.oneTimeOrderService.sendConfirmationSms(this.order.OrderID ,+this.order.Balance ,localStorage.paymentBy == 0 ? 1 : 0).subscribe((res:any)=>{
               // if(this.innerWidth<=480)
               // this.router.navigate(['../verificationCode']);
               // else
                  this.openDialog(res);
                });
            //     this.showProgress=true
            //     if(this.paymentBy==1)
            //     {   
            //       var last2 = this.year.slice(-2);
            //       if(this.month.length==1)
            //       this.month="0"+this.month;
            //       this.date1=this.month+last2;
            //         if(!this.paymentForm.value.nvCardNumber.startsWith("x"))
            //         this.token=null;
            //       this.orderService.setOrderPayment(this.paymentForm.value.nvCardNumber,this.date1.replace("/",""),
            //       this.paymentForm.value.cvv,this.paymentForm.value.identityNumber,this.paymentForm.value.nvCardholderName,Number(this.order.Balance)).subscribe((res:any)=>
            //       {
            //        if(res.error==true)
            //        {
            //         this.showProgress=false;
            //         this.isClickPayment=false;
            //         this.proxy.showError(res.responseMessage,"");
            //        }
            //        else
            //       {
                 
            //         if(this.order.IsDelivery==1)
            //         {
            //           this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2,this.Address,this.lat,this.lng).subscribe((res:any)=>
            //           {
            //                 this.router.navigate(['../endOrder']);
            //                });
            //               }
            //                 else
            //                 this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2).subscribe((res:any)=>
            //                 {
            //        this.router.navigate(['../endOrder']);
            //         });
            //       }
            //        });
            //     }
            //    else 
            //   {
            //     if(this.order.IsDelivery==1)
            //     {
            //     this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2,this.Address,this.lat,this.lng).subscribe((res:any)=>
            //     {
            //           this.router.navigate(['../endOrder']);
            //          });
            //         }
            //           else
            //           this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2).subscribe((res:any)=>
            //           {
            //  this.router.navigate(['../endOrder']);
            //   }); 
            // }
              }
              //this.setUserCreditCard();
             });
          }
        }
        else
        {
          let email=null;
          if(this.paymentForm.value.nvEmail!="")
          email=this.paymentForm.value.nvEmail;
        this.orderService.setUpdateOrder22(this.order.OrderID,this.paymentForm.value.nvOrderName,this.paymentForm.value.nvPhone,email,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,PaymentBy
        ,status,note,1).subscribe((res:any)=>
        {
            localStorage.MENU_ID=0;
            this.showProgress=false;
            localStorage.paymentBy=this.paymentBy;
        //  this.setUserCreditCard();
        {
          this.oneTimeOrderService.sendConfirmationSms(this.order.OrderID,+this.order.Balance ,localStorage.paymentBy == 0 ? 1 : 0).subscribe((res:any)=>{
         // this.matDialog.closeAll();
         // if(this.innerWidth<=480)
         // this.router.navigate(['../verificationCode']);
         // else
         this.openDialog(res);
          });
      //     this.showProgress=true
      //     if(this.paymentBy==1)
      //     {   
      //       var last2 = this.year.slice(-2);
      //       if(this.month.length==1)
      //       this.month="0"+this.month;
      //       this.date1=this.month+last2;
      //         if(!this.paymentForm.value.nvCardNumber.startsWith("x"))
      //         this.token=null;
      //       this.orderService.setOrderPayment(this.paymentForm.value.nvCardNumber,this.date1.replace("/",""),
      //       this.paymentForm.value.cvv,this.paymentForm.value.identityNumber,this.paymentForm.value.nvCardholderName,Number(this.order.Balance)).subscribe((res:any)=>
      //       {
      //        if(res.error==true)
      //        {
      //         this.showProgress=false;
      //         this.isClickPayment=false;
      //         this.proxy.showError(res.responseMessage,"");
      //        }
      //        else
      //       {
           
      //         if(this.order.IsDelivery==1)
      //         {
      //           this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2,this.Address,this.lat,this.lng).subscribe((res:any)=>
      //           {
      //                 this.router.navigate(['../endOrder']);
      //                });
      //               }
      //                 else
      //                 this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2).subscribe((res:any)=>
      //                 {
      //        this.router.navigate(['../endOrder']);
      //         });
      //       }
      //        });
      //     }
      //    else 
      //   {
      //     if(this.order.IsDelivery==1)
      //     {
      //     this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2,this.Address,this.lat,this.lng).subscribe((res:any)=>
      //     {
      //           this.router.navigate(['../endOrder']);
      //          });
      //         }
      //           else
      //           this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2).subscribe((res:any)=>
      //           {
      //  this.router.navigate(['../endOrder']);
      //   }); 
      // }
        }
          });
        }
  });
  }

removeSelectedCountry(event:any){
    // check if the country exists and remove from selectedCountries
  this.proxy.hideKeyboard(event);
}

keytab(event){
  let element = event.srcElement.nextElementSibling; // get the sibling element

  if(element == null)  // check if its null
      return;
  else
      element.focus();   // focus if not null
}

keytabAddress(event){
  this.openMap();
  // let element = event.srcElement.nextElementSibling; // get the sibling element

  // if(element == null)  // check if its null
  //     return;
  // else
  //     element.focus();   // focus if not null
}

 handleKeyDown()
{   
  // element.attr('readonly', 'readonly'); // Force keyboard to hide on input field.
  // element.attr('disabled', 'true'); // Force keyboard to hide on textarea field.
  // setTimeout(function() {
  //     element.blur();  //actually close the keyboard
  //     // Remove readonly attribute after keyboard is hidden.
  //     element.removeAttr('readonly');
  //     element.removeAttr('disabled');
  // }, 100);


  if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur();
  }
}

  openDialog(res :any) {

    if (res.error==false )
      {
       const myRes = res
    localStorage.ADDRESS2=localStorage.ADDRESS;
    localStorage.LAT2=localStorage.LAT;
    localStorage.LONG2=localStorage.LONG;
   // this.matDialog.closeAll();

   

    if (myRes.responseMessage == "NoSMS")
      {
       this.goToPayment(3)
       
        return
      }
        
        
     


    this.dialogRef = this.matDialog.open(VerificationCodeComponent, { disableClose: true });

    this.dialogRef.afterClosed().subscribe(result =>{ 
      //console.log('The dialog was closed');
     
        this.goToPayment(result)

   } )

  }
  
  }

  sendConfirmationSms()
{
  this.oneTimeOrderService.sendConfirmationSms(this.order.OrderID , +this.order.Balance ,localStorage.paymentBy == 0 ? 1 : 0).subscribe((res:any)=>{
  
    

      this.openDialog(res);
       localStorage.isClickPayment="true";
     });
}


goToPayment(result : any)
{
  if (result != null)
    {
switch (result){
// backPress 
case 1 : 
{
    this.matDialog.closeAll();
    if(localStorage.oneTimeOrderOrOrder=='oneTimeOrder')
    this.router.navigate(["../payment"])
    else
   this.router.navigate(["../securePayment"])
};
//openDialog

 
 case 2: 
 { 
 this.dialogRef  = this.matDialog.open(VerificationCodeComponent, {
   backdropClass: 'cdk-overlay-transparent-backdrop',
   hasBackdrop: false,
   disableClose:false
 });

 this.dialogRef.afterClosed().subscribe(result =>
  
  { 


  
    this.goToPayment(result)

} )
 break;
};



//newPassword after OK of sms
case 3:  
{ 

  //20_11_24 Naomi to chack if has location 

if(this.order.IsDelivery==1)
  {
    if (this.order.Lat == 0 && this.order.Lng == 0)
    {
      confirm("יש לנו בעיה עם הכתובת בבקשה בחר מחדש");
      return
    }
  }

   this.myAdd = (this.Address != "") ? this.Address :this.order.Address 
 
  this.dataService.showProgress.next(true);
        this.progress.emit();
        localStorage.isClickPayment=true;
        let IsFromLink=0;
        if(sessionStorage.deeplink==this.order.RestaurantID)
        IsFromLink=1;
        if(localStorage.paymentBy=="1"|| localStorage.paymentBy=="2")
        {   
       
            
//בדיקה




if(localStorage.paymentBy=="1")
{
  this.date1=localStorage.date;
  if(!this.paymentForm.value.nvCardNumber.startsWith("x"))
  this.token=null;

  let credit = this.paymentForm.value.nvCardNumber.replaceAll(" ", "");
          this.oneTimeOrderService.setOrderPayment(credit,localStorage.date.replace("/",""),
          this.paymentForm.value.cvv,this.paymentForm.value.identityNumber,this.address.nvOrderName,Number(this.order.Balance),localStorage.nvCardholderName).subscribe
          ((res:any)=>
            this.myResult(res , IsFromLink)
          );
   }
   else if(localStorage.paymentBy=="2")
   {
    this.oneTimeOrderService.setOrderPayment("4580458045804580","0000",
    "000","000000000",this.address.nvOrderName,Number(this.order.Balance)," ",true).subscribe
    ((res:any)=>
       this.myResult(res ,IsFromLink)
    );
   }


     
          
        }
       else 
      {
        if(this.order.IsDelivery==1)
        {
        this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2,this.myAdd,this.order.Lat+"",this.order.Lng+"",IsFromLink).subscribe((res:any)=>
        {
          this.router.navigate(['../endOrder']);
          this.noItem();
          
             });
            }
              else
              this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,2,IsFromLink).subscribe((res:any)=>
              {
                this.router.navigate(['../endOrder']);
                this.noItem();
      }); 
    }
  break;
  };
}

}
}

 myResult(res:any , IsFromLink: any)
{
 if(res.error==true)
 {
  this.showProgress=false;
  this.isClickPayment=false;
  this.matDialog.closeAll();
  this.proxy.showError(res.responseMessage,"");
 }
 else
{
  if(res.responseMessage!="OK")
    {
//18_11_24


this.dialogRef3 =  this.matDialog.open(PopupIsCorectOrderComponent,{data : {
        
myNumber:this.paymentForm.value.nvPhone,


} 
,  disableClose: true 
} );

this.dialogRef3.afterClosed().subscribe(result => {
//console.log('The dialog was closed');
if (result == "false") 
{
this.showProgress=false;
        this.isClickPayment=false;
        this.matDialog.closeAll();
}
else
{ 

      this.showProgress=false;
 
      this.dialogRef2 =  this.matDialog.open(IFrameCreditCardComponent,{data : {
        
        myurl:res.responseMessage,

//1Sucsses
        // onSucsses: (isSucsses: boolean) => {
        //   if (isSucsses) {
        //     //console.log("saassaaaaaa")
            
        //   }
        // }
        
      } 
        ,  disableClose: true 
      } );
     
      this.dialogRef2.afterClosed().subscribe(result => {
        //console.log('The dialog was closed');
          if (result == "true") 
          this.end(IsFromLink)
        else
        {
        this.showProgress=false;
        this.isClickPayment=false;
        this.matDialog.closeAll();
        }
        
      })
     
    }
  })
  }
  else
  {
   this.end(IsFromLink)
}
}
 }

end(IsFromLink : number )
{
  
    if(this.order.IsDelivery==1)
    {
      this.orderService.setUpdateOrder5(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2,this.myAdd,this.order.Lat+"",this.order.Lng+"",IsFromLink).subscribe((res:any)=>
      {
        
     
            this.router.navigate(['../endOrder']);
            this.noItem();

           });
          }
            else
            this.orderService.setUpdateOrder4(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,0,2,IsFromLink).subscribe((res:any)=>
            {
              this.router.navigate(['../endOrder']);
              this.noItem();

    });
  
}

  noItem()
  {
    let priceTotal="0 - פריטים";
    localStorage.PRICE=0;
    localStorage.PRICE_TOTAL=priceTotal;
    this.dataService.price.next(priceTotal);
    this.changePrice.emit();
    this.showProgress=false;
    this.dataService.showProgress.next(false);
    this.progress.emit();
    this.matDialog.closeAll();
  }

  setUserCreditCard()
  {
    localStorage.paymentBy=this.paymentBy;
    if(this.paymentBy==1)
    {
      var last2 = this.year.slice(-2);
      if(this.month.length==1)
      this.month="0"+this.month;
      this.date1=this.month+last2;
      this.date1=this.paymentForm.value.month+this.paymentForm.value.year;
    localStorage.date=this.date1;
    }
    this.oneTimeOrderService.sendConfirmationSms(this.order.OrderID ,+this.order.Balance ,localStorage.paymentBy == 0 ? 1 : 0).subscribe((res:any)=>{
   // if(this.innerWidth<=480)
   // this.router.navigate(['../verificationCode']);
   // else
   this.openDialog(res);
      localStorage.isClickPayment="true";
    });

  }

  payment()
  {
    if(this.isClickPayment==false || localStorage.isClickPayment=="true")
    {
    this.isClickPayment=true;
    ////console.log("payment");
    this.valid=true;
    if(this.paymentForm.valid==true)
    {
      this.openLoader();
    if(this.isCreditPayment==true)
    {
    if(this.paymentForm.valid)
  //this.setUserCreditCard();
  this.getOrderDetails();
  else
  this.isClickPayment=false;
  }
  else if(this.isAnotherAddress)
  {
    if(this.paymentForm.valid)
 //this.setUserCreditCard();
 this.getOrderDetails();
  else
  this.isClickPayment=false
  }
  else
 //this.setUserCreditCard();
 this.getOrderDetails();
}
else
this.isClickPayment=false;
}
}

  creditPayment()
  {
    if(localStorage.DELIVERY==1)
    {
       this.createForm();
       this.place();
       //this.IsDelivery=1;
       if(localStorage.ADDRESS!=""  && localStorage.ADDRESS!=undefined && localStorage.ADDRESS!='undefined' && localStorage.ADDRESS!=null &&  localStorage.ADDRESS!="null")
       {
       this.address.nvStreet=localStorage.ADDRESS;
       this.lat=localStorage.LAT;
       this.lng=localStorage.LONG;
     
       this.orderService.getOrderDetails(false).subscribe((res:any)=>{
         this.order=res;
         this.deliveryPrice=this.order.DeliveryPrice;
         this.isDelivery=1;
       });
       }
    }
       else
       {
       this.createForm2();
      // this.IsDelivery=0;
       }
       this.paymentBy=1;
       localStorage.paymentBy=1;
this.isCreditPayment=true;
this.paymentCreditImg='assets/images/Pay_Page_Chack_Box_On.png';
this.cashPaymentImg='assets/images/Pay_Page_Chack_Box_Off.png';
this.applePayPaymentImg= 'assets/images/Pay_Page_Chack_Box_Off.png';
if(this.payments!=null)
for(let j=0;j<this.payments.length;j++)
{
  this.payments[j].imageNumber=0;
}
  }

  cashPayment()
  {
    this.paymentBy=0;
    localStorage.paymentBy=0;
this.isCreditPayment=false;
this.cashPaymentImg='assets/images/Pay_Page_Chack_Box_On.png';
this.paymentCreditImg='assets/images/Pay_Page_Chack_Box_Off.png';
this.applePayPaymentImg= 'assets/images/Pay_Page_Chack_Box_Off.png';

if(this.payments!=null)
for(let j=0;j<this.payments.length;j++)
{
  this.payments[j].imageNumber=0;
}
this.createForm3();
  }

  ApplePayPayment()
  {
    this.paymentBy=2;
    localStorage.paymentBy=2;
this.isCreditPayment=false;
this.cashPaymentImg='assets/images/Pay_Page_Chack_Box_Off.png';
this.paymentCreditImg='assets/images/Pay_Page_Chack_Box_Off.png';
this.applePayPaymentImg='assets/images/Pay_Page_Chack_Box_On.png';
if(this.payments!=null)
for(let j=0;j<this.payments.length;j++)
{
  this.payments[j].imageNumber=0;
}
this.createForm3();
  }

  paymentsBy(i)
  {
    this.createForm();
    this.place();
    this.cashPaymentImg='assets/images/Pay_Page_Chack_Box_Off.png';
    this.paymentCreditImg='assets/images/Pay_Page_Chack_Box_Off.png';
for(let j=0;j<this.payments.length;j++)
{
  this.payments[j].imageNumber=0;
}
this.payments[i].imageNumber=1;
this.isCreditPayment=false;
 //if(i==1)
 this.paymentBy=1;
// {
//   if(this.isAnotherAddress==false)
//   this.router.navigate(['../giftCard']); 
//   else if(this.addressForm.valid)
//   this.router.navigate(['../giftCard']); 
// }
// else if(i==2)
// {
//   this.router.navigate(['../codeWorker']); 
// }
  }

  anotherAddress()
  {
   this.isAnotherAddress=true;
   this.anotherAddressImg='assets/images/Pay_Page_Chack_Box_On.png';
   this.myAddressImg='assets/images/Pay_Page_Chack_Box_Off.png';
  }

  myAddress()
  {
    this.isAnotherAddress=false;
    this.myAddressImg='assets/images/Pay_Page_Chack_Box_On.png'
    this.anotherAddressImg='assets/images/Pay_Page_Chack_Box_Off.png';
  }

  isValidInput(fieldName): boolean {
    if(this.valid==true)
    return this.paymentForm.controls[fieldName].invalid ;
    // && (this.registerForm.controls[fieldName].dirty || this.registerForm.controls[fieldName].touched)
    ;
  }

  isValidInputPhone(fieldName): boolean {
    if(this.valid==true)
    {
     if(this.paymentForm.controls[fieldName].value == undefined || this.paymentForm.controls[fieldName].value == "")
    // return true;
     //this.errorPhone="שדה חובה"
    // else
    // this.errorPhone="עליך להכניס מספר טלפון שמתחיל בספרות  05xxxxxxxx";
   //else
    return this.paymentForm.controls[fieldName].invalid;
    }
  }

  isValidInputMail(fieldName): boolean {
    if(this.valid==true)
    {
    // if(this.paymentForm.controls[fieldName].value == undefined || this.paymentForm.controls[fieldName].value == "")
    // this.errorMail="שדה חובה"
    // else
    // this.errorMail="כתובת מייל אינה תקינה";
    return this.paymentForm.controls[fieldName].invalid;
    }
  }

  place()
  {
    this.citiesList = [
      {id: 1, name: "אילת"}
   ];

   var southWest = new google.maps.LatLng( 29.490725, 34.732479);
   var northEast = new google.maps.LatLng( 30.123217, 35.144384 );
   var bangaloreBounds = new google.maps.LatLngBounds( southWest, northEast );
    this.mapsAPILoader.load().then(() => { 
      var options = {
        componentRestrictions: {country: "IL"},
        bounds: bangaloreBounds,
        strictBounds: true ,
       }; 
      this.geoCoder = new google.maps.Geocoder;
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement,options);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
  
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.lat = ""+(place.geometry.location.lat());
          this.lng =""+(place.geometry.location.lng());
          this.Address = place.name;
          this.AddressMe = (""+place.name);
          this.zoom = 12;
          this.isChangeAdress=1;
          this.orderService.getOrderDetails(false).subscribe((res:any)=>{
            this.order=res;
            this.note=this.order.Note;
            if(this.order.Note==null || this.order.Note=='')
            this.note=null;
            this.isDelivery=0;
            // this.orderService.setOrderAddress(this.Address,this.lat,this.lng).subscribe((res:any)=>{
            //   if(res.responseMessage=="the delivery out of bounds")
            //   {
            //     this.proxy.showError("הכתובת שלך מחוץ לטווח ","");  
            //     this.addressForm.value.nvStreet="";
            //     this.searchElementRef=null;
            //   }
            //   else
            //   {
            //    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
            //      this.order=res;
            //      if(this.order.IsDelivery==1)
            //      this.isDelivery=1;
            //      this.deliveryPrice=this.order.DeliveryPrice;
            // });

            //למה לשלוח TotalPrice??
            this.orderService.setUpdateOrder(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,null,
              null,Number(this.order.TotalPrice),1,this.note,0,this.Address,this.lat,this.lng,null,null,null,null,null,0)
              .subscribe((res:any)=>
              {
           if(res.responseMessage=="the delivery out of bounds")
     {
       this.proxy.showError("הכתובת שלך מחוץ לטווח ","");  
       this.paymentForm.value.nvStreet="";
       this.searchElementRef=null;
     }
     else
     {
      this.orderService.getOrderDetails(false).subscribe((res:any)=>{
        this.order=res;
        if(this.order.IsDelivery==1)
        this.isDelivery=1;
        this.deliveryPrice=this.order.DeliveryPrice;
    });
  }});
});});});});
  }

  openMap()
  {
    this.local();
    sessionStorage.pageBack="payment";
   // if(this.innerWidth<=480)
    this.router.navigate(['../map']); 
    //else
   // this.matDialog.open(Map2Component)
  }

  openLoader()
  {
    this.matDialog.open(LoaderComponent, { disableClose: true });
  }

  startLocal()
  {
    this.paymentBy=localStorage.paymentBy;
 if(this.paymentBy==0)
 this.cashPayment();
 else if (this.paymentBy==1)
 this.creditPayment();
 else
 this.ApplePayPayment();
 
  }

  startLocal2()
  {
    if(localStorage.ADDRESS!="")
    {
      this.place();
      this.paymentForm.value.nvStreet=localStorage.ADDRESS;
      this.Address = localStorage.ADDRESS;
      if(localStorage.ADDRESS!=""  && localStorage.ADDRESS!=undefined && localStorage.ADDRESS!='undefined' && localStorage.ADDRESS!=null &&  localStorage.ADDRESS!="null")
    this.address.nvStreet=localStorage.ADDRESS;
    this.lat=localStorage.LAT;
    this.lng=localStorage.LONG;
     
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=res;
      this.deliveryPrice=this.order.DeliveryPrice;
      this.isDelivery=1;
    });
    
    }
   if(localStorage.phone!=undefined &&  localStorage.phone!='undefined')
    this.address.nvPhone=localStorage.phone;
    if(localStorage.orderName!=undefined && localStorage.orderName!='undefined' && localStorage.orderName!="null")
    this.address.nvOrderName=localStorage.orderName;
    if(localStorage.email!=undefined && localStorage.email!='undefined')
    this.address.nvEmail=localStorage.email;
    if(localStorage.enter!=undefined && localStorage.enter!='undefined')
    this.address.nvEnter=localStorage.enter;
    this.address.nvfloor=localStorage.floor;
    this.address.nvHouseNumber=localStorage.numberHoush;
    if(localStorage.identityNumber!=undefined && localStorage.identityNumber!='undefined' && localStorage.identityNumber!=null)
    this.methodsPayment.UserIdentity=localStorage.identityNumber;
    if(localStorage.nvCardholderName!=undefined && localStorage.nvCardholderName!='undefined' && localStorage.nvCardholderName!=null)
    this.methodsPayment.UserName=localStorage.nvCardholderName;
    if(localStorage.nvCardNumber!=undefined && localStorage.nvCardNumber!='undefined')
    this.methodsPayment.CardNumber=localStorage.nvCardNumber;
    if(localStorage.cvv!=undefined && localStorage.cvv!='undefined')
    //this.methodsPayment.card_cvv=localStorage.cvv;
    this.paymentForm.controls["cvv"].setValue(localStorage.cvv);  
    if(localStorage.date!=undefined && localStorage.date!='undefined' && localStorage.date!="")
    {
      this.date = new FormControl(moment(localStorage.date,"MM/YY"));
      let date=localStorage.date;
      date=date.replace("/","");
      this.paymentForm.controls["year"].setValue(date.slice(2));  
      this.paymentForm.controls["month"].setValue(date.slice(0,2));    
      //      this.yearElement.nativeElement.value = date.slice(2);
      // this.monthElement.nativeElement.value = date.slice(0,2);
      // this.paymentForm.value.month.setValue(localStorage.date.slice(0, 2));
      // this.paymentForm.value.year.setValue(localStorage.date.slice(2));
      this.methodsPayment.CardDate = localStorage.date.slice(0, 2) + "/" + localStorage.date.slice(2);

      this.date1=this.methodsPayment.CardDate;
    }

  }
}
