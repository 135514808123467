<div class="fontSize" style="align-items: center; width: auto; border: none; font-family: OpenSansHebrewBold; font-size: 18px;">
    <form class="form-signin"> 
      <div class="form-group">
    
      <br>
      <br class="br">
      <div  style="padding: 15px;   text-align: center;
     color: #41c777; font-size: 19px; 
      ">?האם זה מספר הטלפון שלך</div>
        <div class="body" style="padding: 15px; text-align: center; font-size: 16px;
        color: black;"> {{phoneNumber}}</div>
                <br> 
                  <div  class="btm" style="font-size: 15px;">
                    <p (click)="end()"  class="p" style="cursor: pointer;color: #41c777; font-weight: bold ;">אישור</p>
                    <p (click)="cancel()"  class="p2" style="cursor: pointer;">החלף מספר</p>
                  </div>              
                  </div>         
    </form>
  </div>





