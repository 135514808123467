import { Injectable } from '@angular/core';
import { AppProxy, HttpMethod } from "./app-proxy";
import { ServerResponse } from 'http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class OneTimeOrderService 
{
    constructor(private proxy: AppProxy ,private router: Router,public dialog: MatDialog){}  
  

    createOneTimeUser()
    {
        return new Observable(observer => {
            let signature=this.proxy.md5_(this.proxy.SECRET_KEY +  "OneTime");
             let url= this.proxy.baseQAUrl+"OneTimeOrder/CreateOneTimeUser?"+"signature="+signature;
             this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
               .subscribe(
                 result => {
                     let res: any = result;
                     if (res.error==false)
                      {
                       let resList: ServerResponse = res.responseMessage;
                       this.proxy.ACCESS_TOKEN=resList;
                       localStorage.outOrIn="התנתק"
                       sessionStorage.ACCESS_TOKEN = resList;
                       observer.next(resList);
                     }
                     else this.proxy.showError("שגיאה בכניסת משתמש","");
                   },
                 error => console.log('oops', error));
                  });
           } 


sendConfirmationSms(OrderID:number , TotalPayment : number , PaymentType:number )
{
  let url= this.proxy.baseQAUrl+"OneTimeOrder/sendConfirmationSms?OrderID="+OrderID + "&TotalPayment="+ TotalPayment + "&PaymentType="+ PaymentType;
  return new Observable(observer => {
  this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
            {
          observer.next(res);
            }
          // if (res.error==false && res.responseMessage=="ok")
          //  {
          else this.proxy.showError("שגיאה","");
          // }
        },
      error => console.log('oops', error));
  });
}

setOrderSmsConfirmation(OrderID:number,SMSCode:string,innerWidth:any)
{
  let url= this.proxy.baseQAUrl+"OneTimeOrder/setOrderSmsConfirmation?OrderID="+OrderID+"&SMSCode="+SMSCode;
  return new Observable(observer => {
  this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
    .subscribe(
      result => {
          let res: any = result;
         // if(innerWidth<=480)
         // {
          // if (res.error==false && res.responseMessage=="ok")
            {
              observer.next(res);
            }
         // else
        //  {
       //   this.proxy.showError("קוד שגוי הזן שנית","");
        //   this.router.navigate(["/verificationCode"]);
        //   observer.next(res);
        //  }
        //  }
         // else
          //observer.next(res);
        },
      error => console.log('oops', error));
  });
}

setOrderPayment(card_mask:string,card_validity:string,card_cvv:string,user_identity:string,invoice_name:string,TotalPrice:number , card_owner_name : string , IsApplePay: boolean = false)
{
 let url= this.proxy.baseQAUrl+"OneTimeOrder/setOrderPayment?card_mask="+card_mask+"&card_validity="+card_validity+"&card_cvv="+card_cvv+"&user_identity="+user_identity+"&invoice_name="+invoice_name
  +"&TotalPrice="+TotalPrice+"&card_owner_name="+card_owner_name+"&IsApplePay="+IsApplePay;
  return new Observable(observer => {
  this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
    .subscribe(
      result => {
          let res: any = result;
         // if (res.error==false)
           {
           // let resList: UserEditProfile = res;
            observer.next(res);
       
          }
        //  else this.proxy.showError(res.responseMessage,"");
        },
      error => 
      {
        //console.log('oops', error)
       this.proxy.showError("סירוב כרטיס אשראי","");
    });
    
  });
}


}